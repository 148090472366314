import { useEffect, useState } from "react"
import { isSSR } from "helpers/ssrCheck"

/**
 * Scroll to the top of a page and keep track of the scrolling position
 */
export const useScrollToTop = () => {
  // The scroll position, initialised using the current Y offset
  // if we are in a browser or 0 if this is a SSR build.
  const [scrollPosition, setScrollPosition] = useState(
    isSSR ? 0 : window.scrollY
  )

  useEffect(() => {
    /**
     * Scroll position update handler
     */
    function onScroll() {
      // Capture the scroll position
      setScrollPosition(window.scrollY)

      // Stop listening when the window has scrolled to the top
      if (window.scrollY === 0) {
        window.removeEventListener("scroll", onScroll)
      }
    }

    // Add passive scroll event listener
    window.addEventListener("scroll", onScroll, { passive: true })

    // Scroll to the top when rendering is complete
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      })
    })

    // Clean up scroll listener
    return function cleanup() {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return scrollPosition
}
