import PropTypes from "prop-types"
import React, { forwardRef, useState } from "react"

//
// A container with button-like behaviour
//
const ClickableContainer = forwardRef((props, ref) => {
  // Props
  const {
    shadows,
    shadowsPressed,
    rounded,
    href,
    onClick,
    layout = "flex",
    style, // Extra styles for container
    styleInner, // Extra styles for inner container
    className, // Extra class for container
    classNameInner, // Extra classes for inner container
    children,
  } = props

  // State of press
  const [isPressed, setPressed] = useState(false)

  // Handle mouse down
  const handleMouseDown = () => {
    setPressed(true)
  }

  // Handle mouse up
  const handleMouseUp = () => {
    setTimeout(() => {
      setPressed(false)
    }, 25)
  }

  const handleTouchStart = (event) => {
    setPressed(true)
  }

  const handleTouchEnd = (event) => {
    setTimeout(() => {
      setPressed(false)
    }, 25)
  }

  const handleClick = (event) => {
    // Prevent <a> link from being loaded
    event.preventDefault()
    // Wait for button to come up before invoking callback
    setTimeout(() => {
      onClick()
    }, 75)
  }

  return (
    // Container and outer shadows
    <a
      href={href}
      ref={ref}
      role="button"
      tabIndex="-1"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={handleClick}
      onKeyDown={handleClick}
      style={style ? style : {}}
      className={`
        select-none
        cursor-pointer
        focus:outline-none
        no-tap-overlay
        no-double-tap-zoom
        transform-gpu
        transition-shadow- duration-100- ease-in-out-
        relative
        ${rounded ? rounded : ""}
        ${!isPressed ? shadows : ""}
        ${isPressed ? "scale-99" : ""}
        ${className ? className : ""}
      `}
    >
      {/* Children */}
      <div
        style={styleInner ? styleInner : {}}
        className={`
          w-full h-full
          ${layout}
          ${rounded ? rounded : ""}
          ${isPressed ? shadowsPressed : ""}
          ${classNameInner ? classNameInner : ""}
        `}
      >
        {children}
      </div>
      {/* Inner shadows */}
      <div
        className={`
          absolute
          inset-0
          transition-shadow duration-100 ease-in-out
          ${rounded ? rounded : ""}
          ${isPressed ? shadowsPressed : ""}
        `}
      />
      {/* Gradient overlay */}
      {isPressed && (
        <div
          className={`
            absolute
            inset-0
            bg-gradient-to-br from-black to-white
            opacity-5 
            ${rounded ? rounded : ""}
          `}
        />
      )}
    </a>
  )
})

ClickableContainer.propTypes = {
  shadows: PropTypes.string.isRequired,
  shadowsPressed: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ClickableContainer
