import { useContentItem } from "context/contentItemsContext"
import { useCountries } from "context/countryContext"

//
// Custom hook generate a Hypertext Reference (href)
// for a link to content in the store.
//
export const useStoreLink = (contentRef) => {
  // Get content item from the context map
  const contentItem = useContentItem(contentRef)

  // Determine the store country
  const { storeURL } = useCountries()

  if (!contentItem) {
    return
  }
  return `/${storeURL}/store/${contentItem.type}s/${contentItem.id}`
}
