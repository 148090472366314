import { HAS_TABBAR, useLayoutDispatcher } from "context/layoutContext"
import { useEffect } from "react"

//
// Custom hook to enable/disable the tab bar for a page
//
export const useTabBar = (hasTabBar = false) => {
  const dispatch = useLayoutDispatcher()

  useEffect(() => {
    dispatch({
      type: HAS_TABBAR,
      payload: {
        hasTabBar: hasTabBar,
      },
    })
  }, [hasTabBar, dispatch])
}
