import { useEffect, useState } from "react"

//
// Custom hook to build a map of content items for a Content Items provider.
//
export const useContentItemsMap = (contentItems, selectedContentItem) => {
  // Keep a map of content ref --> Content item
  const [contentItemsMap, setContentItemsMap] = useState(new Map())

  useEffect(() => {
    if (!contentItems) {
      return
    }
    const map = new Map()
    // Capture content items collection
    for (const contentItem of contentItems) {
      map.set(contentItem.ref, contentItem)
    }
    // Add selected content item
    if (selectedContentItem) {
      map.set(selectedContentItem.ref, selectedContentItem)
    }
    setContentItemsMap(map)
  }, [contentItems, selectedContentItem])

  return contentItemsMap
}
