import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AudiobookIcon from "components/icons/contentTypes/audiobookIcon"
import PodcastIcon from "components/icons/contentTypes/podcastIcon"
import RadioIcon from "components/icons/contentTypes/stationIcon"
import AspectRatioBox from "components/utils/aspectRatioBox"
import ClickableContainer from "components/utils/clickableContainer"
import RemoteImage from "components/utils/remoteImage"
import BreakpointContext from "context/breakpointContext"
import { useContentItem } from "context/contentItemsContext"
import { Screen, useScreenContext } from "context/screenContext"
import {
  SELECTED_ITEM,
  useSelectedItemDispatchContext,
} from "context/selectedItemContext"
import { ContentType, getColourForContentItem } from "helpers/contentTypes"
import { size } from "helpers/responsiveSize"
import { useCallToAction } from "hooks/useCallToAction"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useStoreLink } from "hooks/useStoreLink"
import PropTypes from "prop-types"
import React, { memo, useContext, useMemo } from "react"

//
// A Content Card
//
const ContentCard = memo(({ contentRef }) => {
  // Check if component is mounted
  const isMounted = useMountOnLoad()

  // Screen context
  const { screen } = useScreenContext()

  // Grab the content item from the context
  const contentItem = useContentItem(contentRef)

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Store link for item
  const storeLink = useStoreLink(contentRef)

  // Use item selection dispatcher from context
  const dispatchSelectedItem = useSelectedItemDispatchContext()

  // Get CTA detail
  const { isLoaded: isCTALoaded, text: ctaText } = useCallToAction(contentItem)

  // Author/authors
  const author = useMemo(() => {
    if (contentItem === undefined) {
      return
    }
    let author = contentItem.author
    let author2 = contentItem.author2
    if (author && author2) {
      return `${author} & ${author2}`
    } else if (author) {
      return author
    } else {
      return undefined
    }
  }, [contentItem])

  return (
    <>
      {/* Check for component mount to control the reveal */}
      {isMounted && (
        <ClickableContainer
          // layout="grid"
          shadows="neumorphic-2-outer tablet:neumorphic-3-outer desktop:neumorphic-5-outer"
          shadowsPressed="neumorphic-2-inner tablet:neumorphic-3-inner desktop:neumorphic-5-inner"
          rounded="rounded-xl tablet:rounded-2xl desktop:rounded-3xl"
          href={storeLink}
          style={{
            marginBottom: size(bpt, "5.2vw", "3.3vw", "2.8vw", "3.3vw"),
          }}
          className="col-span-3 tablet:col-span-4 laptop:col-span-3"
          onClick={() => {
            dispatchSelectedItem({
              type: SELECTED_ITEM,
              payload: contentItem,
            })
          }}
        >
          {/* Card shape */}
          <AspectRatioBox
            mobileRatio="150%"
            styleInner={{
              gridTemplateRows: "1fr 1fr",
            }}
            className="w-full"
            classNameInner="grid"
          >
            {/* Image container */}
            <AspectRatioBox mobileRatio="100%">
              {/* Cover art */}
              {contentItem?.artwork && (
                <RemoteImage
                  imagePath={contentItem.artwork}
                  aspectRatio="1:1"
                  mobileImageSize={125}
                  tabletImageSize={210}
                  laptopImageSize={200}
                  desktopImageSize={285}
                  className="
                    w-full
                    h-auto
                    rounded-tl-xl tablet:rounded-tl-2xl desktop:rounded-tl-3xl
                    rounded-tr-xl tablet:rounded-tr-2xl desktop:rounded-tr-3xl"
                />
              )}
              {/* Missing cover art */}
              {contentItem && !contentItem.artwork && (
                <div
                  className="
                    w-full
                    h-full
                    bg-grey
                    rounded-tl-xl tablet:rounded-tl-2xl desktop:rounded-tl-3xl
                    rounded-tr-xl tablet:rounded-tr-2xl desktop:rounded-tr-3xl
                    flex
                    items-center
                    justify-center"
                >
                  <FontAwesomeIcon
                    icon="image"
                    fixedWidth={true}
                    size={size(bpt, "3x", "5x", "6x", "8x")}
                    className="text-grey-light"
                  />
                </div>
              )}
            </AspectRatioBox>

            {/* Metadata container */}
            <div
              style={{
                gridTemplateRows: `${
                  contentItem?.type === ContentType.PODCAST
                    ? "repeat(2, auto) 1fr auto"
                    : "repeat(3, auto) 1fr auto"
                }`,
                paddingTop: size(bpt, "2vw", "1.6vw", "1.1vw", "1.1vw"),
                paddingBottom: size(bpt, "2vw", "1.6vw", "1.1vw", "1.1vw"),
                paddingLeft: size(bpt, "3vw", "2vw", "1.4vw", "1.4vw"),
                paddingRight: size(bpt, "3vw", "2vw", "1.4vw", "1.4vw"),
              }}
              className="grid"
            >
              {/* Icon */}
              <div
                style={{
                  width: size(bpt, "3.3vw", "2.2vw", "1.7vw", "1.7vw"),
                  height: size(bpt, "3.3vw", "2.2vw", "1.7vw", "1.7vw"),
                }}
              >
                {contentItem?.type === ContentType.AUDIOBOOK && (
                  <AudiobookIcon />
                )}
                {contentItem?.type === ContentType.PODCAST && <PodcastIcon />}
                {contentItem?.type === ContentType.STATION && <RadioIcon />}
              </div>

              {/* Title */}
              <p
                dangerouslySetInnerHTML={{
                  __html: contentItem?.title,
                }}
                style={{
                  fontSize: size(bpt, "2.8vw", "1.8vw", "1.4vw", "1.4vw"),
                  marginTop: size(bpt, "1vw", "0.8vw", "0.5vw", "0.5vw"),
                }}
                className={`
                  font-bold
                  tracking-tight tablet:tracking-normal
                  leading-tight
                  ${
                    contentItem?.type === ContentType.PODCAST
                      ? "line-clamp-2"
                      : "line-clamp-1"
                  }
                `}
              />

              {/* Subtitle: Audiobook author */}
              {contentItem?.type === ContentType.AUDIOBOOK && author && (
                <p
                  style={{
                    fontSize: size(bpt, "2.6vw", "1.7vw", "1.2vw", "1.2vw"),
                  }}
                  className="
                      tracking-tighter laptop:tracking-normal
                      line-clamp-1"
                >
                  by {author}
                </p>
              )}

              {/* Subtitle: Station location */}
              {contentItem?.type === ContentType.STATION &&
                contentItem.location && (
                  <p
                    style={{
                      fontSize: size(bpt, "2.6vw", "1.7vw", "1.2vw", "1.2vw"),
                    }}
                    className="
                      tracking-tighter laptop:tracking-normal"
                  >
                    {contentItem.location}
                  </p>
                )}

              {/* Spacer: make sure last row sticks to the card bottom! */}
              <div />

              {/* Final row */}
              <div className="flex items-end justify-between">
                {/* Left slot */}
                <div>
                  {/* Duration for Audiobooks */}
                  {contentItem?.type === ContentType.AUDIOBOOK &&
                    contentItem?.duration && (
                      <p
                        style={{
                          fontSize: size(bpt, "2.2vw", "1.4vw", "1vw", "1vw"),
                        }}
                        className="text-blue"
                      >
                        {contentItem?.duration}
                      </p>
                    )}

                  {/* Live ping for Stations */}
                  {contentItem?.type === ContentType.STATION && (
                    <div className="flex items-center relative">
                      <div
                        className="
                          h-1.5 tablet:h-2 desktop:h-3
                          w-1.5 tablet:w-2 desktop:w-3
                          absolute 
                          bg-red 
                          rounded-full"
                      />
                      <div
                        className="
                          h-1.5 tablet:h-2 desktop:h-3
                          w-1.5 tablet:w-2 desktop:w-3
                          bg-red-light
                          rounded-full
                          animate-ping-slow"
                      />
                      <p
                        style={{
                          fontSize: size(bpt, "2.2vw"),
                          marginLeft: size(bpt, "1vw"),
                        }}
                        className="
                          tablet:ml-1 desktop:ml-2
                          tablet:text-xxs desktop:text-sm
                          tracking-tighter desktop:tracking-normal
                          text-red"
                      >
                        Live
                      </p>
                    </div>
                  )}
                </div>

                {/* Right slot: Store screen has a CTA */}
                <div>
                  {(screen === Screen.STORE || screen === Screen.SEARCH) &&
                    isCTALoaded &&
                    ctaText && (
                      <div
                        style={{
                          fontSize: size(
                            bpt,
                            "1.8vw",
                            "1.05vw",
                            "0.8vw",
                            "0.8vw"
                          ),
                          paddingTop: "0.1vw",
                          paddingLeft: size(
                            bpt,
                            "1vw",
                            "0.5vw",
                            "0.5vw",
                            "0.5vw"
                          ),
                          paddingRight: size(
                            bpt,
                            "1vw",
                            "0.5vw",
                            "0.5vw",
                            "0.5vw"
                          ),
                        }}
                        className={`
                        bg-${getColourForContentItem(contentItem)}
                        leading-relaxed
                        text-white
                        rounded-full
                        uppercase
                        font-semibold
                        tracking-wider
                      `}
                      >
                        {ctaText}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </AspectRatioBox>
        </ClickableContainer>
      )}
    </>
  )
})

ContentCard.propTypes = {
  contentRef: PropTypes.string.isRequired,
}

export default ContentCard
ContentCard.whyDidYouRender = true
