import { useLocation } from "@reach/router"
import ContentCard from "components/contentCard/contentCard"
import Metadata from "components/utils/metadata/metadata"
import { ContentItemsProvider } from "context/contentItemsContext"
import { SUBTITLE, useLayoutDispatcher } from "context/layoutContext"
import { Screen, useSetScreen } from "context/screenContext"
import { useContentItemsMap } from "hooks/useContentItemsMap"
import { useFooter } from "hooks/useFooter"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import { useScrollToTop } from "hooks/useScrollToTop"
import { useTabBar } from "hooks/useTabBAr"
import GridLayout from "layouts/gridLayout"
import React, { useEffect } from "react"

/**
 * A static page for a predetermined collection of content items.
 * NOTE: The data for collections is built by `gatsby-node.js` statically!
 */
const StoreCollection = ({ pageContext }) => {
  // Set the screen
  useSetScreen(Screen.STORE)

  // Grab the collection title
  const collectionTitle = pageContext.collectionTitle

  // Build a map of collection content items
  const contentItemsMap = useContentItemsMap(pageContext.collectionItems)

  // Layout dispatcher for subtitle updates
  const layoutDispatcher = useLayoutDispatcher()

  // Check if the component is mounted
  const isMounted = useMountOnLoad()

  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Display the footer
  useFooter(true)

  // Don't use a tab bar
  useTabBar(false)

  // Set the page subtitle to the collection title
  useEffect(() => {
    if (!isMounted) {
      // Make sure the subtitle appears by waiting for mount event...
      return
    }
    if (collectionTitle) {
      layoutDispatcher({
        type: SUBTITLE,
        payload: {
          navbarSubtitle: collectionTitle,
          navbarSubtitleColor: "grey",
        },
      })
    }
  }, [collectionTitle, layoutDispatcher, isMounted])

  // Scroll to the top on load
  let scrollPosition = useScrollToTop()

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      {collectionTitle && (
        <Metadata
          title={collectionTitle}
          useStrapLine={true}
          pathname={location.pathname}
        />
      )}

      {/* Don't display until we're scrolled to the top, to prevent judder */}
      {scrollPosition === 0 && (
        <>
          {/* Collection items */}
          <GridLayout
            type="fluid"
            container={false}
            offsets={false}
            className="col-span-full"
          >
            <ContentItemsProvider contentItemsMap={contentItemsMap}>
              {pageContext.collectionItems?.map((contentItem) => {
                return (
                  <ContentCard
                    key={contentItem.ref}
                    contentRef={contentItem.ref}
                  />
                )
              })}
            </ContentItemsProvider>
          </GridLayout>
        </>
      )}
    </>
  )
}

export default StoreCollection
StoreCollection.whyDidYouRender = true
