import * as React from "react"
import { createContext, useContext } from "react"

//
// A context provider that stores the content items
// in a map for quick retrieval.
//
const ContentItemsContext = createContext()

//
// Content Items Provider
//
export const ContentItemsProvider = ({ contentItemsMap, children }) => {
  return (
    <ContentItemsContext.Provider value={contentItemsMap}>
      {children}
    </ContentItemsContext.Provider>
  )
}

//
// Hook to use a content item by content ref
//
export const useContentItem = (contentRef) => {
  const context = useContext(ContentItemsContext)
  if (context === undefined) {
    throw new Error("useContentItem must be used within a ContentItemsProvider")
  }
  return context.get(contentRef)
}

//
// Hook to use a collection of content item by content refs
//
export const useContentItems = (contentRefs) => {
  const context = useContext(ContentItemsContext)
  if (context === undefined) {
    throw new Error("useContentItem must be used within a ContentItemsProvider")
  }
  // Normalise refs first
  const refs = contentRefs
    .split(",")
    .map((ref) => {
      return ref.trim()
    })
    .filter((ref) => {
      return ref !== ""
    })
  return refs.map((contentRef) => {
    return context.get(contentRef)
  })
}
